import { default as _404zbUFvgvMaKMeta } from "/home/ubuntu/product-module/pages/404.vue?macro=true";
import { default as account7s4z5kAaSHMeta } from "/home/ubuntu/product-module/pages/account.vue?macro=true";
import { default as bundlesCAVxa829WyMeta } from "/home/ubuntu/product-module/pages/bundles.vue?macro=true";
import { default as cartUm6tNkODx9Meta } from "/home/ubuntu/product-module/pages/cart.vue?macro=true";
import { default as indexnYiGYuW9zCMeta } from "/home/ubuntu/product-module/pages/checkout/index.vue?macro=true";
import { default as contactUsbJNyVB3SPZMeta } from "/home/ubuntu/product-module/pages/contactUs.vue?macro=true";
import { default as _91id_93b2OOx1n8C9Meta } from "/home/ubuntu/product-module/pages/custom/[id].vue?macro=true";
import { default as faqHAR9cdtftbMeta } from "/home/ubuntu/product-module/pages/faq.vue?macro=true";
import { default as indexmILF7rAwO1Meta } from "/home/ubuntu/product-module/pages/index.vue?macro=true";
import { default as loginpr1LJ979ShMeta } from "/home/ubuntu/product-module/pages/login.vue?macro=true";
import { default as order_45placedR8QH4aSYp8Meta } from "/home/ubuntu/product-module/pages/order-placed.vue?macro=true";
import { default as contentYr9QfOBJVPMeta } from "/home/ubuntu/product-module/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failedo4Sp58sJg5Meta } from "/home/ubuntu/product-module/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionTHxB5LAUbVMeta } from "/home/ubuntu/product-module/pages/processing-transaction.vue?macro=true";
import { default as _91id_9356ID8kEoB6Meta } from "/home/ubuntu/product-module/pages/products/[id].vue?macro=true";
import { default as indexFBYrRglyE8Meta } from "/home/ubuntu/product-module/pages/products/index.vue?macro=true";
import { default as registerInwj21VxeTMeta } from "/home/ubuntu/product-module/pages/register.vue?macro=true";
import { default as resetJkmQ5n81H3Meta } from "/home/ubuntu/product-module/pages/reset.vue?macro=true";
import { default as staticfQ68ezZ6OyMeta } from "/home/ubuntu/product-module/pages/static.vue?macro=true";
import { default as tenantLoginjIhwcV6P7BMeta } from "/home/ubuntu/product-module/pages/tenantLogin.vue?macro=true";
import { default as verify845zbtBp5RMeta } from "/home/ubuntu/product-module/pages/verify.vue?macro=true";
import { default as wishlistuxMg77rxQBMeta } from "/home/ubuntu/product-module/pages/wishlist.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/home/ubuntu/product-module/pages/404.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: account7s4z5kAaSHMeta || {},
    component: () => import("/home/ubuntu/product-module/pages/account.vue")
  },
  {
    name: "bundles",
    path: "/bundles",
    component: () => import("/home/ubuntu/product-module/pages/bundles.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/home/ubuntu/product-module/pages/cart.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexnYiGYuW9zCMeta || {},
    component: () => import("/home/ubuntu/product-module/pages/checkout/index.vue")
  },
  {
    name: "contactUs",
    path: "/contactUs",
    meta: contactUsbJNyVB3SPZMeta || {},
    component: () => import("/home/ubuntu/product-module/pages/contactUs.vue")
  },
  {
    name: "custom-id",
    path: "/custom/:id()",
    meta: _91id_93b2OOx1n8C9Meta || {},
    component: () => import("/home/ubuntu/product-module/pages/custom/[id].vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/product-module/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/product-module/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginpr1LJ979ShMeta || {},
    component: () => import("/home/ubuntu/product-module/pages/login.vue")
  },
  {
    name: "order-placed",
    path: "/order-placed",
    component: () => import("/home/ubuntu/product-module/pages/order-placed.vue")
  },
  {
    name: "pages-key-content",
    path: "/pages/:key()/content",
    component: () => import("/home/ubuntu/product-module/pages/pages/[key]/content.vue")
  },
  {
    name: "payment-failed",
    path: "/payment-failed",
    component: () => import("/home/ubuntu/product-module/pages/payment-failed.vue")
  },
  {
    name: "processing-transaction",
    path: "/processing-transaction",
    meta: processing_45transactionTHxB5LAUbVMeta || {},
    component: () => import("/home/ubuntu/product-module/pages/processing-transaction.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    meta: _91id_9356ID8kEoB6Meta || {},
    component: () => import("/home/ubuntu/product-module/pages/products/[id].vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexFBYrRglyE8Meta || {},
    component: () => import("/home/ubuntu/product-module/pages/products/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerInwj21VxeTMeta || {},
    component: () => import("/home/ubuntu/product-module/pages/register.vue")
  },
  {
    name: "reset",
    path: "/reset",
    component: () => import("/home/ubuntu/product-module/pages/reset.vue")
  },
  {
    name: "static",
    path: "/static",
    component: () => import("/home/ubuntu/product-module/pages/static.vue")
  },
  {
    name: "tenantLogin",
    path: "/tenantLogin",
    meta: tenantLoginjIhwcV6P7BMeta || {},
    component: () => import("/home/ubuntu/product-module/pages/tenantLogin.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/home/ubuntu/product-module/pages/verify.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/home/ubuntu/product-module/pages/wishlist.vue")
  }
]